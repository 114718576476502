// 
// _demos.scss
// 

// Buttons demo
.button-items {
    margin-left: -8px;
    margin-bottom: -12px;

    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}

// Modals
.bs-example-modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
}

[dir="rtl"] {
    .modal-open {
        padding-left: 0px !important;
    }
}

// Icon demo ( Demo only )
.icon-demo-content {
    color: var(--#{$variable-prefix}gray-500);

    i {
        font-size: 24px;
        margin-right: 10px;
        color: var(--#{$variable-prefix}gray-600);
        transition: all 0.4s;
        vertical-align: middle;
    }

    svg {
        margin-right: 10px;
        transition: all 0.4s;
        height: 20px;
    }

    .col-lg-4 {
        margin-top: 24px;

        &:hover {
            i, svg {
                color: $primary;
                transform: scale(1.5);
            }
        }
    }
}

// Grid

.grid-structure {
    .grid-container {
        background-color: $gray-100;
        margin-top: 10px;
        font-size: .8rem;
        font-weight: $font-weight-medium;
        padding: 10px 20px;
    }
}

[data-layout-mode="dark"]{
    .colorscheme-cardradio{
        .form-check-label{
            background-color: var(--#{$variable-prefix}dark);
        }
        .bg-light{
            background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.1)!important;
        }

        .bg-soft-light {
            background-color: rgba(var(--#{$variable-prefix}dark-rgb),.1)!important;
        }

        .dark{
            .bg-dark{
                background-color: $card-bg!important;
            }
        }
    }
}